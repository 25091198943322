import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  // useParams
} from "react-router-dom";
import FlowerGirlApp from './FlowerGirlApp';
import App from './App';


require('react-dom');
window.React2 = require('react');
console.log(window.React1 === window.React2);

export default function Routing() {
  return (
    <Router>
      <div>
        <Switch>
        <Route path="/fg/:secondary/:id" component={FlowerGirlApp} />
          <Route path="/fg/:id" component={FlowerGirlApp} />
          <Route path="/fg" component={FlowerGirlApp} />
          <Route path="/:id" component={App} />
        </Switch>
      </div>
    </Router>
  );
}

