import React, { useEffect } from 'react';
// import { Helmet } from 'react-helmet';
import { useState,
  // useEffect
  } from 'react';

import {
  useParams
} from "react-router-dom";

import ConnectMuseumButton from "./components/museum/ConnectMuseumButton";
import ConnectedMuseum from "./components/museum/ConnectedMuseum";

// import styled from "styled-components";
import Web3 from "web3";
// import { convertUtf8ToHex } from "@walletconnect/utils";

import Web3Modal from "web3modal";
import WalletLink from "walletlink";
// @ts-ignore
import WalletConnectProvider from "@walletconnect/web3-provider";
// @ts-ignore
import Fortmatic from "fortmatic";
// import Torus from "@toruslabs/torus-embed";
// import Authereum from "authereum";
import { Bitski } from "bitski";
// import evmChains from 'evm-chains';

import { connectWallet } from "./util/api";
import './FlowerGirlApp.css';

// console.log(mg_images);


// Web3modal instance
let web3Modal

// Chosen wallet provider given by the dialog window
let provider;


// Address of the selected account
let selectedAccount;

const INFURA_PROJECT = "9ac642f9317e4368a84b14998d5b93ba";
const FORTMATIC_KEY = "pk_live_B3FB63D898ED9616";

const FG_CONTRACT = "0x2eFa07CaC3395599db83035D196F2A0e7263F766";

/**
 * Setup the orchestra
 */
function initFG() {

  console.log("Initializing example");
  console.log("WalletConnectProvider is", WalletConnectProvider);
  console.log("Fortmatic is", Fortmatic);
  console.log("window.web3 is", window.web3, "window.ethereum is", window.ethereum);

  // Check that the web page is run in a secure context,
  // as otherwise MetaMask won't be available
  if(window.location.protocol !== 'https:') {
    console.log("Not loaded in https")
    return;
  }

  // Tell Web3modal what providers we have available.
  // Built-in web browser provider (only one can exist as a time)
  // like MetaMask, Brave or Opera is added automatically by Web3modal
  const providerOptions = {
    bitski: {
      package: Bitski, // required
      options: {
        clientId: "6d644ded-1722-4acc-8bbf-3eb51bde2777", // required
        callbackUrl: "https://bot.exchange.io/bitski/callback.html" // required
      },
    },
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: INFURA_PROJECT,
      }
    },
    fortmatic: {
        package: Fortmatic,
        options: {
          key: FORTMATIC_KEY,
        }
      },
    'custom-coinbase': {
      display: {
        logo: '/images/coinbase.png', 
        name: 'Coinbase',
        description: 'Scan with WalletLink to connect',
      },
      options: {
        appName: 'app', // Your app name
        networkUrl: `https://mainnet.infura.io/v3/${INFURA_PROJECT}`,
        chainId: 1,
      },
      package: WalletLink,
      connector: async (_, options) => {
        const { appName, networkUrl, chainId } = options
        const walletLink = new WalletLink({
          appName
        });
        const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
        await provider.enable();
        return provider;
      },
    }
  };

  web3Modal = new Web3Modal({
    cacheProvider: false, // optional
    providerOptions, // required
    theme: "dark",
    disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
  });
  web3Modal.clearCachedProvider();

  console.log("Web3Modal instance is", web3Modal);
}


function thumbFG(tokenId) {
  return `https://moshpitflowergirls.blob.core.windows.net/imgs/IMGS_THUMBNAILS/${tokenId}.jpg`
}


/**
 * Kick in the UI action after Web3modal dialog has chosen a provider
 */
async function fetchAccountDataFG(id, secondary=false) {

  // Get a Web3 instance for the wallet
  const web3 = new Web3(provider);

  console.log("Web3 instance is", web3);

  const accounts = await web3.eth.getAccounts();

  // MetaMask does not give you all accounts, only the selected account
  console.log("Got accounts", accounts);
  selectedAccount = accounts[0];

  let user;
  if(id !== ''){
    if(secondary === 'add'){
      user = await connectWallet(id, '', selectedAccount);
    } else {
      user = await connectWallet(id, selectedAccount);
    }
  }
  console.log(user);

  return await tokensFromWallet(selectedAccount);

}

async function tokensFromWallet(selectedAccount) {
  const FG_owned_ids = []

  //await fetch(`https://bot.exchange.io/api/v1/wallet/${selectedAccount}`).then((r) => r.json()).then((j) => {
  await fetch(`https://bot.exchange.io/api/v1/wallet/${selectedAccount}`).then((r) => r.json()).then((j) => {
    let tokens = j.tokens?.[FG_CONTRACT] || [];
    tokens.forEach((fgid) => {
      FG_owned_ids.push(fgid);
    });
  }).catch(function(e) {
    console.log("error");
    console.log(e);
  });

  return FG_owned_ids.map((i) => thumbFG(i));
}


  /**
 * Connect wallet button pressed.
 */
async function onConnectFG(id, secondary=false) {

  console.log("Opening a dialog", web3Modal);
  try {
    provider = await web3Modal.connect();

  } catch(e) {
    console.log("Could not get a wallet connection", e);
    return;
  }

  try {
    provider.on("accountsChanged", (accounts) => {
    fetchAccountDataFG(id, secondary);
    });

    // Subscribe to chainId change
    provider.on("chainChanged", (chainId) => {
      fetchAccountDataFG(id, secondary);
    });

    // Subscribe to networkId change
    provider.on("networkChanged", (networkId) => {
      fetchAccountDataFG(id, secondary);
    });
  } catch(e) {
    console.log(e);
    console.log(provider);
  }
  // Subscribe to accounts change


  return fetchAccountDataFG(id, secondary);

}



// const Fortmatic = window.Fortmatic;
// const evmChains = window.evmChains;


function FlowerGirlApp() {

  let { id = '' } = useParams();
  let { wallet = '' } = useParams();
  let { secondary = false } = useParams();
  
  const [connected, setConnected] = useState(false);
  const [images, setImages] = useState(["images/no_flowers.png"]);

  useEffect(() => {
    initFG();
    if (wallet !== '') {

    }
  }, [wallet, ]);

  function doSetImages(_images) {
    try {
      _images.forEach((_image) => {
        new Image().src = _image;
      });
      setImages(_images);
      return true;
    } catch {
      return false;
    }
    
  }

  return (

        <div className="FlowerGirlApp">
          <header className="FlowerGirlApp-header">
            <div id="txDetails"></div>
            <div className="inner">
            { !connected && (
              <ConnectMuseumButton
                  id={"btn-connect"}
                  variant="outlined"
                  className="FlowerGirlApp-link"
                  onClick={(e) => {
                    
                    e.target.style.backgroundImage = "url('/images/fg_checking.png')";
                    console.log(e);
                    console.log(e.target.style.backgroundImage);
                    onConnectFG(id, secondary).then((im) => {
                    console.log(im);
                    let con = doSetImages(im);
                    setConnected(con);
                  });
                }
              }
                  show={!connected}
              ></ConnectMuseumButton>)
            }

            {connected &&
            <>
              <ConnectedMuseum
                  id={"btn-connected"}
                  className="FlowerGirlApp-link"
                  show_images={images}
                  show={connected}
              >
              </ConnectedMuseum>
              <div style={{
                fontSize: '0.5em',
                color: '#030303',
                position: 'absolute',
                bottom: 0,
                right: 0,
                padding: '5px'
              }}>You're connected! All done (for now)!</div>
            </>
            }
            </div>

            </header>
        </div>


  );
}

export default FlowerGirlApp;
