import React from 'react';

const connect = '/images/frame.png';
const connect_pressed = '/images/frame.png';

export default function ConnectMuseum(props) {
    return (
       <img src={connect}
       alt="Connect"
       style={{
           'background': "url('/images/fg_plz_connect.png') no-repeat center 18%",
           backgroundRepeat: 'no-repeat',
           backgroundPosition: 'center 18%',
           // backgroundAttachment: 'fixed',
           backgroundSize: '90%',
           'display': props.show ? 'block' : 'none',
           maxWidth: '100%',
           maxHeight: '100%',
       }}
       onMouseOver={e => e.currentTarget.src = connect_pressed}
       onMouseOut={e => e.currentTarget.src = connect}
       onClick={props.onClick}
       />
    );
}
