import React from 'react';
import {useEffect, useState} from "react";

const connect = '/images/frame.png';
const connect_pressed = '/images/frame.png';

export default function ConnectedMuseum(props) {
    const [show_images, setShowImages] = useState(props.show_images);
    const [display, setDisplay] = useState(0);


    useEffect(() => {
    const interval = setInterval(() => {
      setDisplay(display => display + 1 === show_images.length ? 0 : display + 1);
      console.log(show_images)
        console.log(display)
    }, 3000);
    return () => clearInterval(interval);
  }, []);
    return (
       <img src={connect}
       style={{
           backgroundImage: `url('${show_images[display]}')`,
           backgroundRepeat: 'no-repeat',
           backgroundPosition: 'center 18%',
           // backgroundAttachment: 'fixed',
           backgroundSize: '90%',
           maxWidth: '150%',
           maxHeight: '150%',

       }}
       onMouseOver={e => e.currentTarget.src = connect_pressed}
       onMouseOut={e => e.currentTarget.src = connect}
       onClick={() => {setDisplay(display => display + 1 === show_images.length ? 0 : display + 1);}}
       />
    );
}
