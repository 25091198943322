import React from 'react';

const connect = 'images/cabinet_connect.png';
const connect_pressed = 'images/cabinet_connect_pressed.png';

export default function ConnectArcade(props) {
    return (
       <img src={connect}
       style={{
           'background': "url('images/plz_connect.png') no-repeat center 30% fixed",
           'backgroundSize': '230px',
           'display': props.show ? 'block' : 'none',
       }}
       onMouseOver={e => e.currentTarget.src = connect_pressed}
       onMouseOut={e => e.currentTarget.src = connect}
       onClick={props.onClick}
       />
    );
}
